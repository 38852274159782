import { addBreadcrumb, init as sentryBrowserInit } from '@sentry/browser';
import { init as sentryVueInit } from '@sentry/vue';

const config = {
    // Only send errors and traces 25% of the time
    sampleRate: 0.25,
    tracesSampleRate: 0.25,
    dsn: window.sentry?.dsn,
    environment: window.sentry?.environment,
    ignoreErrors: [
        "Can't find variable: _AutofillCallbackHandler",
        'Unable to preload CSS for',
        "Can't find variable: gmo",
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome-extension:\//i,
        /^chrome:\/\//i,
        // Firefox extensions
        /^resource:\/\//i,
    ],
};

export function initSentryBrowser() {
    if (window.sentry?.dsn) {
        sentryBrowserInit(config);
        trackImageLoadingErrors();
    }
}

export function initSentryVue(Vue) {
    if (window.sentry?.dsn) {
        sentryVueInit({ Vue: Vue, ...config });
    }
}

function trackImageLoadingErrors() {
    document.body.addEventListener(
        'error',
        (event) => {
            if (!event.target) return;

            if (event.target.tagName === 'IMG') {
                addBreadcrumb({
                    message: `Failed to load image: ${event.target.src}`,
                    level: 'warning',
                });
            } else if (event.target.tagName === 'LINK') {
                addBreadcrumb({
                    message: `Failed to load css: ${event.target.href}`,
                    level: 'warning',
                });
            }
        },
        true, // useCapture - necessary for resource loading errors
    );
}
