document.addEventListener('DOMContentLoaded', function () {
    const faqTriggers = document.querySelectorAll("[id^='faq-trigger']");

    faqTriggers.forEach((trigger) => {
        trigger.addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                const checkbox = trigger.previousElementSibling;
                if (checkbox && checkbox.type === 'checkbox') {
                    checkbox.checked = !checkbox.checked;
                }
            }
        });
    });
});
