import { googleTagManager, tealium } from './tracking';

document.addEventListener('DOMContentLoaded', async () => {
    const formPagesToTrack = ['form_page_success', 'form_page_rejection'];

    // setting page group after redirect to '/thank-you'
    if (formPagesToTrack.some((page) => window.pageGroup?.startsWith(page))) {
        tealium.setPageGroup(window.pageGroup);
        googleTagManager.setPageGroup(window.pageGroup);
    }

    // form pages have custom tracking logic (except for /thank-you')
    if (
        formPagesToTrack.some((page) => window.pageGroup?.startsWith(page)) ||
        !window.pageGroup?.startsWith('form_page')
    ) {
        console.log('firing page view');
        tealium.trackPageview();
        googleTagManager.trackPageview();
    }
});
