/**
 * GoogleTagManager class extends TrackingScript class.
 * This class is responsible for managing Google Tag Manager related operations.
 */
import TrackingScript from './tracking-script';
import { getActivatedExperiments } from '../feature-management';
import { cookiesAccepted } from '../cookies';

export default class GoogleTagManager extends TrackingScript {
    //Queue for tracking events that were called before the new cookie wall was accepted
    preCookieAcceptQueue = [];
    //The default pageData
    pageData = {};
    //Boolean to indicate if page data has been set
    hasSetPageData = false;
    //Boolean to detect if Pageview with '_inactive_city' was fired (have to remove '_inactive_city' for the first one)
    seenPageViewInactiveCity = false;

    /**
     * Constructor for GoogleTagManager class.
     * It calls the inject method and sets the page data.
     */
    constructor() {
        super();
        if (!window.gtmTrackingEnabled) {
            return;
        }
        this.needsCookies = false;
        this.inject().then(() => {
            this.sendEventsAfterCookieConsent();
        });
    }

    /**
     * This method checks if the Google Tag Manager script can be injected and if it can, it injects the script and noscript tags.
     */
    async inject() {
        this.addScript();
        this.addNoScript();
    }

    /**
     * This method creates and injects the Google Tag Manager script tag into the head of the document.
     */
    addScript() {
        //script for the google tag manager
        let GTMScript = document.createElement('script');
        GTMScript.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${window.gtmContainerId}');`;
        // Append the script to the head of the document
        document.head.insertBefore(GTMScript, document.head.firstChild);
    }

    /**
     * This method creates and injects a noscript tag into the body of the document.
     */
    addNoScript() {
        //Append a noscript tag to the body
        let noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${window.gtmContainerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.insertBefore(noscript, document.body.firstChild);
    }

    /**
     * This method sends events after the cookie consent has been given.
     */
    sendEventsAfterCookieConsent() {
        let eventInterval = setInterval(() => {
            if (this.hasCookieConsentForNewBanner()) {
                clearInterval(eventInterval);
                if (this.preCookieAcceptQueue.length) {
                    while (this.preCookieAcceptQueue.length) {
                        const nextEvent = this.preCookieAcceptQueue.shift();
                        this.executeTrackingEvent(nextEvent);
                    }
                }
            }
        }, 2000);
    }

    /**
     * This method executes a tracking event.
     * @param {Function} eventFunction - The tracking event function to be executed.
     */
    executeTrackingEvent(eventFunction) {
        //check if cookie has been accepted
        if (!this.hasCookieConsentForNewBanner()) {
            this.preCookieAcceptQueue.push(eventFunction);
            return;
        }
        try {
            eventFunction();
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('tracking event error:', err.message); // eslint-disable-line no-console
            }
        }
    }

    /**
     * This method tracks an event with a given name.
     * @param {string} eventName - The name of the event to be tracked.
     * @param {Object} options - The options for the event tracking.
     * @param {Object} options.customData - The custom data for the event.
     * @param {boolean} options.addUtmData - Whether to add UTM data to the event.
     */
    trackEventWithName(eventName, { customData, addUtmData } = {}) {
        this.executeTrackingEvent(() => {
            //default values
            addUtmData = addUtmData === undefined ? true : addUtmData;
            customData = customData === undefined ? {} : customData;

            // Remove UTM data if consent was not given to analytical cookies
            if (!cookiesAccepted('analytical')) {
                addUtmData = false;
            }
            if (!this.hasSetPageData) {
                this.setPageData();
            }

            let payload = { event: eventName };
            if (customData) payload = { ...customData, ...payload };
            if (!this.hasSetPageData) {
                this.setPageData();
            }
            payload = { ...this.pageData, ...payload };
            if (addUtmData) payload = { ...this.getUtmData(), ...payload };
            payload._clear = true;
            payload.utag_data = { tealium_event: 'custom_dev_none' };

            window.dataLayer.push(payload);
        });
    }

    /**
     * This method sets the page group.
     * @param {string} pageGroup - The page group to be set.
     */
    setPageGroup(pageGroup) {
        window.pageGroup = pageGroup;
        this.pageData.pageGroup = pageGroup;
    }

    /**
     * This method sets the page data.
     */
    setPageData() {
        let pageData = {
            homerunURL: window.location.href,
            url: window.location.pathname,
            country: window.country?.code ?? 'country_not_set',
            language: window.language?.code ?? 'language_not_set',
            'ut.env': window.gtmEnvironment ?? 'qa',
        };

        if (window.pageGroup !== 'courier_hub') {
            pageData.city = this.getCityValue();
        }

        let pageGroup = window.pageGroup;
        if (pageGroup) {
            pageData.pageGroup = pageGroup;
        }

        let experimentDetails = getActivatedExperiments();
        if (experimentDetails) {
            pageData.experiment_details = experimentDetails;
        }
        this.pageData = pageData;
        this.hasSetPageData = true;
    }

    /**
     * This method gets the UTM data.
     * @returns {Object} The UTM data.
     */
    getUtmData() {
        let utmData = {};
        // loop though all window variables that start with utm_ and add these to the object
        for (let key in window) {
            if (key.startsWith('utm_') && window[key]) {
                utmData[key] = window[key];
            }
        }
        return utmData;
    }

    /**
     * This method gets the interactive map value.
     * @returns {string} The interactive map value.
     */
    getInteractiveMapValue() {
        if (window.trackingMapComplete === undefined) {
            return undefined;
        }

        return window.trackingMapComplete ? 'complete' : 'incomplete';
    }

    //Below tracking methods that appear frequently in the codebase
    /**
     * This method tracks a pageview event.
     */
    trackPageview() {
        this.trackEventWithName('Pageview', { addUtmData: false });
    }

    /**
     * This method tracks a city apply form event.
     */
    trackCtaApplyForm() {
        this.trackEventWithName('cta_apply_form', {
            customData: {
                component_name: 'cta',
                component_type: 'cta',
                component_action: 'apply form',
            },
            addUtmData: true,
        });
    }

    /**
     * This method tracks a CTA apply event.
     */
    trackCtaApply() {
        this.trackEventWithName('cta_apply', {
            customData: {
                component_name: window.location.href,
                component_type: 'cta',
                component_action: 'cta_apply',
            },
        });
    }

    /**
     * This method tracks a has viewed form field event.
     * @param {string} name - The name of the field.
     */
    trackHasViewedFormField(name) {
        this.trackEventWithName('hasViewedFieldInForm', {
            customData: {
                fieldName: name,
            },
        });
    }

    /**
     * This method tracks a has filled form field event.
     * @param {string} name - The name of the field.
     */
    trackHasFilledFormField(name) {
        this.trackEventWithName('hasModifiedFieldInForm', {
            customData: {
                fieldName: name,
            },
        });
    }

    /**
     * This method tracks a form validation error event.
     * @param {string} component_name - The name of the component.
     * @param {string} error_message - The error message.
     * @param {string} form_info - The form information.
     */
    trackFormValidationError(component_name, error_message, form_info) {
        this.trackEventWithName('formField_error', {
            customData: {
                component_type: 'form',
                component_name: component_name,
                component_action: 'error',
                error_message: error_message,
            },
        });

        this.trackEventWithName('form_error', {
            customData: {
                component_type: 'form',
                component_name: form_info,
                component_action: 'error',
            },
        });
    }

    /**
     * This method tracks a has submitted form event.
     * @param {string} status - The status of the submission.
     * @param {string} applicantId - The ID of the applicant.
     * @param {string} applicationCity - The city of the application.
     * @param {string} hashed_email - The SHA-256 hashed email of the applicant.
     * @param {string} hashed_phone - The SHA-256 hashed phone number of the applicant.
     */
    trackHasSubmittedForm(status, applicantId, applicationCity, hashed_email, hashed_phone) {
        this.trackEventWithName('application_status', {
            customData: {
                component_name: 'application_status',
                component_type: 'application',
                component_action: status,
                application_city: applicationCity,
                applicantId: applicantId,
                interactive_map: this.getInteractiveMapValue(),
                hashed_email: hashed_email,
                hashed_phone: hashed_phone,
            },
        });
    }

    /**
     * This event is triggered when the user clicks on Apply now and no errors are triggered.
     * @param city - The city of the application.
     * @param applicantId - The ID of the applicant.
     * @param application - The application status.
     * @param hashed_email - The SHA-256 hashed email of the applicant.
     * @param hashed_phone - The SHA-256 hashed phone number of the applicant.
     */
    trackHasClickedApply(city, applicantId, application, hashed_email, hashed_phone) {
        this.trackEventWithName('hasClickedApply', {
            customData: {
                applicationCity: city,
                applicantId: applicantId,
                application: application,
                interactive_map: this.getInteractiveMapValue(),
                hashed_email: hashed_email,
                hashed_phone: hashed_phone,
            },
            addUtmData: true,
        });
    }

    /**
     * This event is triggered when a user interacts with the salary slider
     * @param field - The name of the field that has been interacted with.
     * @param city_slider - The selected city.
     * @param hours_slider - The selected hours.
     * @param age_slider - The selected age.
     */
    trackHasInteractedWithSalarySlider(field, city_slider, hours_slider, age_slider) {
        this.trackEventWithName('slider_interaction', {
            customData: {
                component_type: 'salary_slider',
                component_name: field,
                component_action: 'edit',
                city_slider: city_slider,
                hours_slider: hours_slider,
                age_slider: age_slider,
            },
            addUtmData: true,
        });
    }

    /**
     * This event is triggered when a connection / server error occurs.
     * @param errorStatus - The error status.
     * @param errorMessage - The error message.
     * @param form_info - The form information.
     */
    trackHasNetworkError(errorStatus, errorMessage, form_info) {
        this.trackEventWithName('api_error', {
            customData: {
                component_type: 'form',
                component_action: 'error',
                component_name: form_info,
                error_code: errorStatus,
                error_message: errorMessage,
            },
            addUtmData: true,
        });
        this.trackEventWithName('form_error', {
            customData: {
                component_type: 'form',
                component_name: form_info,
                component_action: 'error',
            },
            addUtmData: true,
        });
    }

    /**
     * This event is triggered when a user clicks the icons on the map.
     * @param {'hub' | 'house' | 'remote'} iconName - The name of the icon.
     */
    trackHasClickedMapIcon(iconName) {
        this.trackEventWithName('map_click', {
            customData: {
                component_name: 'interactiveMap',
                component_type: 'map',
                component_action: 'click',
                city: this.getCityValue(),
                icon: iconName,
            },
            addUtmData: true,
        });
    }

    /**
     * This event is triggered when a user search a new city on the map.
     * @param {string} cityName - The name of the city that has been searched for.
     */
    trackHasSearchedCity(cityName) {
        this.trackEventWithName('interactiveMap_search', {
            customData: {
                component_name: 'interactiveMap',
                component_type: 'searchBox',
                component_action: 'search',
                city: cityName,
            },
            addUtmData: true,
        });
    }

    /**
     * This event is triggered when the user interacts with the map because of zoom in out.
     * @param {boolean} zoomedIn - True if the user zoomed in, false if the user zoomed out.
     */
    trackHasZoomedMap(zoomedIn = true) {
        this.trackEventWithName('map_interaction', {
            customData: {
                component_name: 'interactiveMap',
                component_type: 'map',
                component_action: zoomedIn ? 'zoom_in' : 'zoom_out',
            },
            addUtmData: true,
        });
    }
}
